<template>
  <div>
    <h1 class="subtitle-1 grey--text">Состояние</h1>

    <v-container class="mb-5">
      <div v-if="lastStatus">
        <v-row v-for="source in lastStatus.data" :key="source.name" row wrap>
          <v-col md="4">
            <v-card>
              <v-card-title>
                {{ source.name_ui }}
              </v-card-title>
              <v-card-subtitle>
                {{ source.status == 'on' ? 'В работе' : 'Не доступен' }}
              </v-card-subtitle>
              <v-card-text v-if="source.errors.length > 0">
                <v-icon color="error" dense>mdi-alert-outline</v-icon>
                <span color="error" v-for="error in source.errors" :key="error.code">
                  {{ error.value }} (код {{ error.code }})
                  <v-divider />
                </span>
              </v-card-text>
              <v-card-text v-if="source.warnings.length > 0">
                <v-icon color="warning" dense>mdi-alert-outline</v-icon>
                <span color="warning" v-for="warning in source.warnings" :key="warning.code">
                  {{ warning.value }} (код {{ warning.code }})
                  <v-divider />
                </span>
              </v-card-text>
              <v-divider class="mx-4"></v-divider>
              <v-card-text>
                <span class="font-weight-light">Обновлено</span>
                {{ lastStatus.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4" v-for="device in source.devices" :key="device.name">
            <v-card :class="device.status">
              <v-card-title>
                {{ device.name_ui }}
              </v-card-title>
              <v-card-subtitle v-if="device.status">
                {{ device.status == 'on' ? 'В работе' : 'Не доступен' }}
              </v-card-subtitle>
              <v-card-text>
                <div v-for="item in device.description" :key="item.name">
                  <span class="font-weight-light">{{ item.name_ui }}</span>
                  <div>{{ item.value }}</div>
                </div>
              </v-card-text>
              <v-divider class="mx-4"></v-divider>
              <v-card-text
                ><span class="font-weight-light">Обновлено</span>
                {{ lastStatus.createdAt | moment('YYYY-MM-DD HH:mm:ss') }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else class="caption text-center">Пока нет полученных статусов</div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    kitId() {
      return this.$route.params.kitId
    },
    lastStatus() {
      return this.statuses[0]
    },
    ...mapGetters('kit', ['statuses'])
  },
  mounted() {
    this.fetchStatuses(this.kitId)
  },
  methods: {
    ...mapActions('kit', ['fetchStatuses'])
  }
}
</script>

<style scoped>
.online {
  border-left: 4px solid lightgreen;
}
.offline {
  border-left: 4px solid lightgray;
}
</style>
